import {
  FEATURE_CALENDAR,
  FEATURE_JOBS,
  FEATURE_PUBLICATIONS_CAROUSEL,
  FEATURE_SUBSCRIPTIONS
} from '@/misc/constants';

const features = [
  {
    version: '0.0.1',
    disabledFeatures: [FEATURE_CALENDAR, FEATURE_JOBS, FEATURE_SUBSCRIPTIONS, FEATURE_PUBLICATIONS_CAROUSEL]
  },
  {
    version: '0.1.0',
    disabledFeatures: []
  }
];

export const isFeatureEnabled = (version, feature) => {
  return !features
    .find((item) => item.version === version)
    .disabledFeatures.find((item) => item === feature);
};
